import {ApolloClient} from '@apollo/client';
import {
    Komodita,
    Maybe,
    UcetSkupinaTyp,
    ZadostElektrinaSopHladinaNapeti,
    ZadostSopObchodniPartnerDruh,
    ZadostSopWebBlokInfo,
    ZadostSopWebBlokType,
    ZadostSopWebFormType,
} from '@eon.cz/apollo13-graphql-web';
import Router from 'next/router';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import {RootActionType} from '../../lib/rootAction';
import {PageRoute} from '../PageRouteModel';
import {NotificationsActionCreator} from '../components/notifications/actions/NotificationsActions';
import {NotificationType} from '../components/notifications/model/NotificationModel';
import {redirectURI} from '../constants';
import {
    setActiveStepsSlice,
    setEmailSlice,
    setEndFormSlice,
    setFilenamesSlice,
    setFormKindSlice,
    setFormStepPathSlice,
    setFormTypeSlice,
    setKomoditaSlice,
    setParseIdSlice,
    setSelfcareSlice,
    setTypZakaznikSlice,
    setZmenaodberuTypNapetiSlice,
    setlastFinishedBlockSlice,
} from '../reducers/CommonReducer';
import {FormKind} from '../store/CommonStore';

type CommonActionType = {
    logout: (client: ApolloClient<any>, error?: string | undefined) => void;
    backToD24: (client: ApolloClient<any>, komodita: Komodita | undefined, typ: UcetSkupinaTyp | undefined | null, endOfForm: boolean | undefined) => void;
    reset: () => void;
    resetStore: () => void;
    setActiveSteps: (value: number | undefined) => void;
    setKomodita: (value: Komodita) => void;
    setEmail: (value: string | undefined | null) => void;
    setFilenames: (value: {[key in 'vyrobna' | 'odber' | 'uprava']?: string[]}) => void;
    setEndOfForm: (value: boolean) => void;
    setSelfcare: (value: boolean) => void;
    setParseId: (value: string | undefined) => void;
    setFormStepPath: (value: ZadostSopWebBlokType[] | undefined | Maybe<ZadostSopWebBlokType>[]) => void;
    setFormType: (value: ZadostSopWebFormType | undefined) => void;
    setFormKind: (value: FormKind) => void;
    setZmenaOdberuTypNapeti: (value: ZadostElektrinaSopHladinaNapeti | undefined | Maybe<ZadostElektrinaSopHladinaNapeti>) => void;
    setTypZakaznika: (value: ZadostSopObchodniPartnerDruh) => void;
    setLastFinishedBlok: (value: ZadostSopWebBlokInfo | undefined) => void;
};

/**
 * Custom hook pro redux akci společnou napříč aplikací
 *
 * @return {*}  {CommonActionType}
 */
export const useCommonAction = (): CommonActionType => {
    const dispatch = useDispatch();
    const {addNotification} = NotificationsActionCreator(dispatch);

    return {
        logout: (client: ApolloClient<any>, error?: string | undefined) => {
            setTimeout(() => {
                client.cache
                    .reset()
                    .then(() => {
                        // Reset store
                        window.localStorage.clear();
                        dispatch({type: RootActionType.LOGOUT, payload: undefined});
                        error &&
                            addNotification({
                                type: NotificationType.ERROR,
                                text: error,
                            });
                    })
                    .catch(() =>
                        addNotification({
                            type: NotificationType.ERROR,
                            text: <FormattedMessage id={'error.network'} />,
                        }),
                    );
            }, 200);
        },
        reset: () => {
            // Reset store

            Router.push({pathname: PageRoute.ROOT}).then(() => {
                // Reset store
                dispatch({type: RootActionType.LOGOUT, payload: undefined});
            });
        },
        resetStore: () => {
            // Reset store
            window.localStorage.clear();
            window.sessionStorage.clear();

            // Reset store
            dispatch({type: RootActionType.LOGOUT, payload: undefined});
        },
        backToD24: (client: ApolloClient<any>, komodita, typ, endOfForm) => {
            const redirectURIByTyp =
                typ === UcetSkupinaTyp.ZADATEL
                    ? `${redirectURI}/zadosti?sopWeb=true&dokoncena=${endOfForm}`
                    : `${redirectURI}/${komodita?.toLowerCase()}/zadosti?sopWeb=true&dokoncena=${endOfForm}`;
            client.cache
                .reset()
                .then(() => {
                    window.sessionStorage.clear();
                    window.localStorage.clear();
                    Router.push(redirectURIByTyp);
                })
                .catch((error) => new Error(error));
        },
        setZmenaOdberuTypNapeti: (values) => dispatch(setZmenaodberuTypNapetiSlice(values)),
        setEndOfForm: (value) => dispatch(setEndFormSlice(value)),
        setSelfcare: (value) => dispatch(setSelfcareSlice(value)),
        setActiveSteps: (value) => dispatch(setActiveStepsSlice(value)),
        setKomodita: (value) => dispatch(setKomoditaSlice(value)),
        setEmail: (value) => dispatch(setEmailSlice(value)),
        setFilenames: (value) => dispatch(setFilenamesSlice(value)),
        setParseId: (value) => dispatch(setParseIdSlice(value)),
        setFormStepPath: (value) => dispatch(setFormStepPathSlice(value)),
        setFormType: (value) => dispatch(setFormTypeSlice(value)),
        setFormKind: (value) => dispatch(setFormKindSlice(value)),
        setTypZakaznika: (value) => dispatch(setTypZakaznikSlice(value)),
        setLastFinishedBlok: (value) => dispatch(setlastFinishedBlockSlice(value)),
    };
};

const CommonAction = {
    logout: (client: ApolloClient<any>, error?: string | undefined) => (dispatch: Dispatch) => {
        setTimeout(() => {
            const {addNotification} = NotificationsActionCreator(dispatch);
            client.cache
                .reset()
                .then(() => {
                    // Reset store
                    dispatch({type: RootActionType.LOGOUT});
                    {
                        error &&
                            addNotification({
                                type: NotificationType.ERROR,
                                text: error,
                            });
                    }
                })
                .catch(() =>
                    addNotification({
                        type: NotificationType.ERROR,
                        text: <FormattedMessage id={'error.network'} />,
                    }),
                );
        }, 200);
    },
};
/**
 * Common action creator je možné volat i mimo komponentu. Využití v apolloIniti
 *
 * @param {Dispatch} dispatch
 */
export const CommonActionCreator = (dispatch: Dispatch) => bindActionCreators({...CommonAction}, dispatch);
