import {ZadostSopWebBlokRozcestnikInput} from '@eon.cz/apollo13-graphql-web';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RozcestnikStore} from '../store/RozcestnikStore';

const initialState: RozcestnikStore = {
    komodita: undefined,
};

export const rozcestnikSlice = createSlice({
    name: 'rozcestnik',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setRozcestnikDataSlice: (state: RozcestnikStore, {payload}: PayloadAction<Omit<ZadostSopWebBlokRozcestnikInput, 'email'>>) => ({
            ...state,
            ...payload,
        }),
    },
});

export const {setRozcestnikDataSlice} = rozcestnikSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example, `useSelector((state: RootState) => state.counter.value)`
