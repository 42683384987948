import {PovoleneTypyPriloh} from '@eon.cz/apollo13-graphql-web';
import {ReactElement, ReactNode, useCallback, useMemo, useState} from 'react';
import {createSafeContext, useSafeContext} from './helpers';

type ContextValue = {
    readonly values: {
        readonly povoleneTypyPriloh: {[key: string]: PovoleneTypyPriloh};
        readonly refresh?: boolean;
    };
    readonly setContextValues: (value: ContextValue['values']) => void;
};

export const AppContext = createSafeContext<ContextValue>();

export const useAppContext = () => useSafeContext(AppContext);

export function AppContextProvider({children}: {children: ReactNode}): ReactElement {
    const [values, setValues] = useState<ContextValue['values']>({
        povoleneTypyPriloh: {},
        refresh: false,
    });

    const setContextValues = useCallback(
        (value: ContextValue['values']) =>
            setValues((prevState) => ({
                ...prevState,
                povoleneTypyPriloh: value.povoleneTypyPriloh,
                refresh: value.refresh,
            })),
        [],
    );

    const value = useMemo(
        () => ({
            values,
            setContextValues,
        }),
        [values],
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
