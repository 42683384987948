import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import type {TechnickaSpecifikaceOdberuStore} from '../store/TechnickaSpecifikaceOdberuStore';

export type TechnickaSpecifikaceOdberuInput = {
    readonly hodnotaJistice: number;
    readonly faze: number;
};
const initialState: TechnickaSpecifikaceOdberuStore = {
    hodnotaJistice: 0,
    faze: 0,
};

export const technickaSpecifikaceOdberuSlice = createSlice({
    name: 'technickaSpecifikaceOdberu',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTSODataSlice: (state: TechnickaSpecifikaceOdberuStore, {payload}: PayloadAction<TechnickaSpecifikaceOdberuInput>) => ({
            ...state,
            ...payload,
        }),
    },
});

export const {setTSODataSlice} = technickaSpecifikaceOdberuSlice.actions;
