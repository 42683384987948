import {
    Komodita,
    Maybe,
    ZadostElektrinaSopHladinaNapeti,
    ZadostSopObchodniPartnerDruh,
    ZadostSopWebBlokInfo,
    ZadostSopWebBlokType,
    ZadostSopWebFormType,
} from '@eon.cz/apollo13-graphql-web';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import type {CommonStore, FormKind} from '../store/CommonStore';

const initialState: CommonStore = {
    activeStep: null,
    komodita: Komodita.ELEKTRINA,
    email: undefined,
    formStepPath: [],
    endOfForm: false,
    zmenaOdberuTypNapeti: undefined,
    filenames: {odber: [], vyrobna: []},
    selfcare: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setActiveStepsSlice: (state: CommonStore, {payload}: PayloadAction<number | undefined>) => ({
            ...state,
            activeStep: payload,
        }),
        setKomoditaSlice: (state: CommonStore, {payload}: PayloadAction<Komodita>) => ({
            ...state,
            komodita: payload,
        }),
        setEmailSlice: (state: CommonStore, {payload}: PayloadAction<string | undefined | null>) => ({
            ...state,
            email: payload,
        }),
        setFilenamesSlice: (state: CommonStore, {payload}: PayloadAction<{vyrobna?: string[] | undefined; odber?: string[] | undefined}>) => ({
            ...state,
            filenames: {...state.filenames, ...payload},
        }),
        setEndFormSlice: (state: CommonStore, {payload}: PayloadAction<boolean>) => ({
            ...state,
            endOfForm: payload,
        }),
        setSelfcareSlice: (state: CommonStore, {payload}: PayloadAction<boolean>) => ({
            ...state,
            selfcare: payload,
        }),
        setParseIdSlice: (state: CommonStore, {payload}: PayloadAction<string | undefined>) => ({
            ...state,
            parseId: payload,
        }),
        setFormStepPathSlice: (state: CommonStore, {payload}: PayloadAction<ZadostSopWebBlokType[] | Maybe<ZadostSopWebBlokType>[] | undefined>) => ({
            ...state,
            formStepPath: payload,
        }),
        setZmenaodberuTypNapetiSlice: (state: CommonStore, {payload}: PayloadAction<ZadostElektrinaSopHladinaNapeti | undefined | null>) => ({
            ...state,
            zmenaOdberuTypNapeti: payload,
        }),
        setFormTypeSlice: (state: CommonStore, {payload}: PayloadAction<ZadostSopWebFormType | undefined>) => ({
            ...state,
            formType: payload,
        }),
        setFormKindSlice: (state: CommonStore, {payload}: PayloadAction<FormKind>) => ({
            ...state,
            formKind: payload,
        }),
        setTypZakaznikSlice: (state: CommonStore, {payload}: PayloadAction<ZadostSopObchodniPartnerDruh>) => ({
            ...state,
            typZakaznika: payload,
        }),
        setlastFinishedBlockSlice: (state: CommonStore, {payload}: PayloadAction<ZadostSopWebBlokInfo | undefined>) => ({
            ...state,
            lastFinishedBlok: payload,
        }),
    },
});

export const {
    setActiveStepsSlice,
    setEmailSlice,
    setEndFormSlice,
    setFilenamesSlice,
    setFormKindSlice,
    setFormStepPathSlice,
    setFormTypeSlice,
    setKomoditaSlice,
    setParseIdSlice,
    setTypZakaznikSlice,
    setZmenaodberuTypNapetiSlice,
    setlastFinishedBlockSlice,
    setSelfcareSlice,
} = commonSlice.actions;
