import {Action, AnyAction, bindActionCreators, Dispatch} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {addNotificationSlice, closeNotificationSlice, NotificationAddRequest} from '../reducer/NotificationsReducer';

export type NotificationsActions = {
    readonly addNotification: (notification: NotificationAddRequest) => Action;
    readonly closeNotification: () => Action;
};

export const useAddNotification = () => {
    const dispatch = useDispatch();

    return {
        addNotification: (notification: NotificationAddRequest) => dispatch(addNotificationSlice(notification)),
        closeNotification: () => dispatch(closeNotificationSlice()),
    };
};

const NotificationActionsImpl: NotificationsActions = {
    addNotification: (notification: NotificationAddRequest) => addNotificationSlice(notification),
    closeNotification: () => closeNotificationSlice(),
};

export const NotificationsActionCreator = (dispatch: Dispatch<AnyAction>): NotificationsActions => bindActionCreators({...NotificationActionsImpl}, dispatch);
