import {combineReducers, configureStore, PayloadAction} from '@reduxjs/toolkit';
import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import {notificationsSlice, NotificationsStore} from '../common/components/notifications/reducer/NotificationsReducer';
import {commonSlice} from '../common/reducers/CommonReducer';
import {CommonStore} from '../common/store/CommonStore';
import {rozcestnikSlice} from '../components/rozcestnik/reducer/RozcestnikReducer';
import {RozcestnikStore} from '../components/rozcestnik/store/RozcestnikStore';
import {technickaSpecifikaceOdberuSlice} from '../components/technickaSpecifikaceOdberu/reducer/TechnickaSpecifikaceOdberuReducer';
import {TechnickaSpecifikaceOdberuStore} from '../components/technickaSpecifikaceOdberu/store/TechnickaSpecifikaceOdberuStore';
import {RootActionType} from './rootAction';
const persistConfig = {
    key: 'primary',
    storage,
    whitelist: ['rozcestnik', 'common', 'technickaSpecifikaceOdberu'], // place to select which state you want to persist
};

export type RootState = {
    /* A type definition for the state of the notifications. */
    notifications: NotificationsStore;
    common: CommonStore;
    rozcestnik: RozcestnikStore;
    technickaSpecifikaceOdberu: TechnickaSpecifikaceOdberuStore;
};

const rootReducer = (store: RootState | undefined, action: PayloadAction<any>) => {
    // combine all reducers
    const combined = combineReducers({
        notifications: notificationsSlice.reducer,
        common: commonSlice.reducer,
        rozcestnik: rozcestnikSlice.reducer,
        technickaSpecifikaceOdberu: technickaSpecifikaceOdberuSlice.reducer,
    });

    if (action.type === RootActionType.LOGOUT) {
        // Reset store with passing state of undefined
        return combined(undefined, action);
    }

    // Go normal
    return combined(store, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const makeStore = () => {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'notifications/addNotification'],
                    ignoreActionPaths: ['notifications.notifications'],
                    isSerializable: () => true,
                },
            }),
    });
};

const store = makeStore();
export type Store = ReturnType<typeof store.getState>;

export default store;
