import {createTheme} from '@mui/material';
import {csCZ} from '@mui/material/locale';
import {PaletteOptions} from '@mui/material/styles';
import {useEffect, useMemo, useState} from 'react';

export const AppTheme = {
    DARK: 'dark',
    LIGHT: 'light',
} as const;

export type AppTheme = (typeof AppTheme)[keyof typeof AppTheme];

export const palette = {
    mode: AppTheme.LIGHT,
    primary: {
        main: '#1200E0',
        light: '#ffffff',
        dark: '#261B62',
        contrastText: '#fff',
    },
    secondary: {
        main: '#261B62',
        light: '#78e0ed',
        dark: '#007e8b',
        contrastText: '#fff',
    },
    error: {
        main: '#e13019',
    },
    grey: {
        '500': '#8A8A8E',
    },
    custom: {
        green: {
            main: '#15C851',
            light: '#80e27e',
            dark: '#087f23',
            contrastText: '#fff',
        },
        orange: {
            main: '#ff9800',
            light: '#ffc947',
            dark: '#c66900',
            contrastText: '#000',
        },
        indigo: {
            main: '#3f51b5',
            light: '#757de8',
            dark: '#002984',
            contrastText: '#fff',
        },
        brown: {
            main: '#795548',
            light: '#a98274',
            dark: '#4b2c20',
            contrastText: '#fff',
        },
    },
    background: {
        default: '#f1f1f1',
        paper: '#fff',
    },
} satisfies PaletteOptions;

export const theme = createTheme({
    palette,
});

export const useTheme = () => {
    const [preference, setPreference] = useState<boolean>(false);
    const [darkMode, setDarkMode] = useState<AppTheme | string | null>(() =>
        typeof window === 'undefined' ? AppTheme.LIGHT : window.localStorage.getItem('theme'),
    );

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
            setPreference(prefersDarkMode.matches);
        }
    }, []);

    const changeTheme = (theme: AppTheme) => {
        localStorage.setItem('theme', theme);
        setDarkMode(theme);
    };

    const theme = useMemo(
        () =>
            createTheme(
                {
                    typography: {
                        fontFamily: '"EONBrixSans", arial, sans-serif',
                        fontSize: 15.8,
                    },
                    components: {
                        MuiButton: {
                            styleOverrides: {
                                root: {
                                    borderRadius: 28,
                                    textTransform: 'none',
                                    padding: '14px 32px',
                                    fontWeight: 'bolder',
                                    lineHeight: '18px',
                                },
                                text: {
                                    padding: '14px 32px',
                                },
                            },
                        },
                        MuiIconButton: {
                            styleOverrides: {
                                root: {
                                    color: palette.primary.main,
                                    padding: 0,
                                    width: 48,
                                    height: 48,
                                    '&:hover': {
                                        color: palette.primary ? palette.primary.main : undefined,
                                    },
                                    '&.Mui-disabled': {
                                        color: '#8A8A8E',
                                    },
                                },
                            },
                        },
                        MuiCheckbox: {
                            styleOverrides: {
                                colorSecondary: {
                                    '&$checked': {
                                        color: palette?.secondary?.main,
                                    },
                                },
                            },
                        },
                        MuiFormLabel: {
                            styleOverrides: {
                                root: {
                                    '&$focused': {
                                        color: palette.primary ? palette.primary.dark : undefined,
                                    },
                                },
                            },
                        },
                        MuiInput: {
                            styleOverrides: {
                                underline: {
                                    '&:after': {
                                        borderBottom: `2px solid ${palette.primary ? palette.primary.dark : undefined}`,
                                    },
                                },
                                root: {
                                    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                        display: 'none',
                                        margin: 80,
                                    },
                                },
                            },
                        },
                        MuiTextField: {
                            defaultProps: {
                                variant: 'standard',
                            },
                        },
                        MuiSelect: {
                            defaultProps: {
                                variant: 'standard',
                            },
                        },
                        MuiFormControl: {
                            defaultProps: {
                                variant: 'standard',
                            },
                        },
                    },
                    palette: {
                        ...palette,
                        mode: preference && darkMode === AppTheme.DARK ? AppTheme.DARK : AppTheme.LIGHT,
                    },
                },
                csCZ,
            ),
        [darkMode, preference, palette],
    );
    return {theme, changeTheme};
};
