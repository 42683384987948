import {PropTypes, Theme} from '@mui/material';

import {useTheme} from '@mui/styles';
import {FC, SVGProps} from 'react';
import {Div} from '../styled/Div';

type OwnProps = {
    width?: number;
    height?: number;
    pathFill?: PropTypes.Color | string;
};

const DEFAULT_WIDTH = 100;
const DEFAULT_HEIGHT = 30;
const DEFAULT_PATHFILL = '#FFFFFF';

type Props = SVGProps<SVGSVGElement> & OwnProps;

/**
 * EGD brand logo ve formátu SVG, vložené formou inline svg.
 *
 * @param {number} width - šířka loga (default 100px)
 * @param {number} height - výška loga (default 30px)
 * @param {PropTypes.Color | string} pathFill - barva loga / možné hodnoty 'primary', 'secondary', 'inherit', 'default' (default bílá [theme.palette.common.white])
 */
export const EgdLogo: FC<Props> = ({width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT, pathFill = DEFAULT_PATHFILL}) => {
    const theme = useTheme<Theme>();

    const resolvePathFill = (color: PropTypes.Color | string) => {
        switch (color) {
            case 'primary':
                return theme.palette.primary.main;
            case 'secondary':
                return theme.palette.secondary.main;
            case 'inherit':
                return 'inherit';
            case 'default':
                return theme.palette.common.white;
            default:
                return pathFill;
        }
    };

    const fill = resolvePathFill(pathFill);

    return (
        <Div
            sx={{
                width,
                height,
            }}
        >
            <svg
                version="1.1"
                id="logo"
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                x="0px"
                y="0px"
                viewBox="0 0 283.5 129.3"
                xmlSpace="preserve"
            >
                <g>
                    <path
                        fill={fill}
                        d="M79.5,70.2c0.2-1.8,0.3-3.8,0.3-5.6c0-22.1-14-41-39.3-41C18.2,23.6,0,40.8,0,63.3c0,21.5,15.8,41.4,41.6,41.4
	c17.1,0,30.4-10.2,36.2-21.4l-18.7-4.2c-4.4,4.6-10.5,6.8-16.5,6.8c-9.2,0-17.9-5.4-19.7-15.8H79.5z M23.3,54.7
	c3-8.7,10.7-13.2,18.1-13.2c7.1,0,14,4.3,16.1,13.2H23.3z M283.5,0h-22.9v27.9c-5.5-3-11.2-4.3-16.8-4.3c-20.8,0-39.6,16-39.6,40.7
	c0,23.7,17.8,40.5,41.3,40.5c6.9,0,16.2-2.1,23.7-7.7h14.2V0z M244.8,82.7c-9.9,0-18.2-8.3-18.2-18.4c0-10,8.4-18.4,18.2-18.4
	c10.4,0,17.9,8,17.9,18.1C262.8,73.9,255.9,82.7,244.8,82.7 M88.1,59.3c0,19.9,17.1,35.6,34.8,35.6c4.5,0,10.2-1.1,14.6-3.5v3.4
	c0,10.8-7.8,16.4-15.7,16.4c-5.9,0-11.8-3.1-14.6-9.7l-18.5,4.3c4.8,12.5,16.8,23.6,34,23.6c22.2,0,36-15.3,36-42.6V30.6h-13.9
	c-5.7-4.3-13.2-7-21-7C104,23.6,88.1,39.6,88.1,59.3 M109.2,59.3c0-8,6.6-14.6,14.6-14.6c8,0,14.6,6.6,14.6,14.6
	s-6.6,14.6-14.6,14.6C115.8,73.9,109.2,67.4,109.2,59.3 M167.5,65.1c0,7.9,6.3,14.2,14.2,14.2S196,73,196,65.1s-6.3-14.2-14.2-14.2
	S167.5,57.2,167.5,65.1"
                    />
                </g>
            </svg>
        </Div>
    );
};
