import {ErrorOutlineOutlined} from '@mui/icons-material';
import {AppBar, Button, IconButton, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import {theme} from '../../lib/theme';
import {useCommonAction} from '../Actions/CommonAction';
import {PageRoute} from '../PageRouteModel';
import {EgdLogo} from './layout/EgdLogo';

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        minHeight: 204,
        backgroundImage: "url('/static/images/AppBarBackground.svg')",
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        display: 'block',
        marginBottom: 30,
    },

    message: {
        marginBottom: theme.spacing(2),
    },
    logo: {
        marginLeft: 30,
    },
    top: {
        height: 80,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 20,
        margin: '0 auto',
        maxWidth: 1680,
    },
    wrapper: {
        textAlign: 'center',
    },
    icon: {
        fontSize: 150,
    },
}));

type Props = {
    readonly error?: Error;
    readonly resetErrorBoundary?: () => void;
};

export const ErrorFallback = ({error, resetErrorBoundary}: Props) => {
    const classes = useStyles();
    const {pathname, push} = useRouter();
    const {setActiveSteps} = useCommonAction();
    const handleChangeRoute = () =>
        pathname !== PageRoute.ROOT && push({pathname: PageRoute.ROOT}, undefined, {shallow: true}).then(() => setActiveSteps(undefined));
    return (
        <>
            <div className={classes.top}>
                <IconButton className={classes.logo}>
                    <EgdLogo width={150} height={50} pathFill={theme.palette.error.main} onClick={handleChangeRoute} />
                </IconButton>
            </div>
            <AppBar position="relative" color="secondary" className={classes.appBar} />

            <div className={classes.wrapper}>
                <ErrorOutlineOutlined fontSize="large" classes={{fontSizeLarge: classes.icon}} color="error" />
                <Typography className={classes.message} variant="h2">
                    <FormattedMessage id="error.http.unexpectedContent1" />
                </Typography>
                <Typography className={classes.message} variant="body1">
                    {error?.message}
                </Typography>
                <Button color="primary" variant="contained" disableRipple onClick={resetErrorBoundary}>
                    <FormattedMessage id="button.reset" />
                </Button>
            </div>
        </>
    );
};
