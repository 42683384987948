export const PageRoute = {
    ROOT: '/',
    VSTUP: '/rozcestnik',
    OSOBNI_UDAJE: '/osobniUdaje',
    SPECIFIKACE_UZEMI: '/specifikaceUzemi',
    GEOPORTAL: '/geoportal',
    TECHNICKA_SPECIFIKACE_ODBERU: '/technickaSpecifikaceOdberu',
    UPRESNENI_PREDCHOZIHO_ODBERU: '/upresneniPredchozihoOdberu',
    TECHNICKE_SPECIFIKACE_VYROBNY: '/technickaSpecifikaceVyrobny',
    SOUHRN_ZADOSTI: '/souhrnZadosti',
    PODEKOVANI: '/podekovani',
} as const;
