import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ReactNode} from 'react';
import {NotificationModel, NotificationType} from '../model/NotificationModel';

export type NotificationsStore = {
    readonly notifications: NotificationModel[];
    readonly nextSerial: number;
};
export type NotificationAddRequest = {
    // Notification type. When not specified, INFO is assumed.
    readonly type?: NotificationType;

    // Notification text or node (for rich notifications).
    readonly text: ReactNode;

    // Error object for optional display
    readonly errorObject?: any;

    // Auto-hide time in milliseconds. Use -1 to never autohide. When not specified, time is determined automatically.
    readonly autohideTime?: number;
};

const initialState: NotificationsStore = {
    notifications: [],
    nextSerial: 1,
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addNotificationSlice: (state: NotificationsStore, {payload}: PayloadAction<NotificationAddRequest>) => ({
            ...state,
            notifications: state.notifications.concat([{...(payload as NotificationAddRequest), serial: state.nextSerial}]),
            nextSerial: state.nextSerial + 1,
        }),
        closeNotificationSlice: (state: NotificationsStore) => ({...state, notifications: state.notifications.slice(1)}),
    },
});

export const {addNotificationSlice, closeNotificationSlice} = notificationsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const notifications = (state: NotificationsStore) => state.notifications;

export default notificationsSlice.reducer;
